
import Vue from 'vue';
import MenuContainer from './components/MenuContainer.vue';

interface appData {
	isExpanded: boolean;
}

export default Vue.extend<appData, {}, {}, {}>({
	name: 'app',
	data() {
		return {
			isExpanded: true
		}
	},
	components: {
		MenuContainer,
	}
})
