export interface IMenuItem {
	children: IMenuItem[],
	hasChildren: boolean,
	id: string,
	isCurrent: boolean,
	isExpanded: boolean,
	level: number,
	name: string,
	uri: string,
}

export interface IState {
	menuItems: IMenuItem[],
	url: string,
	contact: string
}

const deafultState: IState = {
	menuItems: [],
	url: '',
	contact: ''
}

export default deafultState;
